<template>
  <div class="columns">
    <div class="column has-text-centered">
      <div :class="loaderOnly ? '' : 'loading-container'">
        <div class="rotating loader-icon">
          <i class="mdi mdi-loading"></i>
        </div>
        <p v-if="!loaderOnly" class="text-subtitle bold">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loaderOnly: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.loading-container {
  margin-top: 120px;
  margin-bottom: 120px;
}

.loader-icon {
  font-size: 120px;
  color: $primary-color;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>
